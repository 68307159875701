export const setCurrentMovieAction = (card, setCurrentMovie, setCurrentSeries, dispatch) => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
    if (window.location.pathname === "/movies") {
        return dispatch(setCurrentMovie(card));
    } else {
        return dispatch(setCurrentSeries(card));
    }
}

const setInvalidInputStyle = (target, inputName, setInputErrorMessage, hintMessage) => {
    setInputErrorMessage(prevState => ({
        ...prevState,
        [inputName]: `${hintMessage || ""}`
    }));
    target.style.borderColor = "red";
}

const setValidInputStyle = (target, inputName, setInputErrorMessage) => {
    setInputErrorMessage(prevState => ({
        ...prevState,
        [inputName]: ``
    }));
    target.style.borderColor = "#e4ebe4";
}

export const validateInput = (target, inputName, setInputErrorMessage, password, setPassword) => {
    const nameRegex = /^[\u0621-\u064Aa-zA-Z]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[a-zA-Z].*[a-zA-Z]).{8,16}$/;
    const phone = /^\d+$/;

    const checkForValidRegex = (regex, errorMessage) => {
        // Use the .test() method with regular expressions instead of the .match() method as it provides better performance.
        if (regex.test(target.value)) {
            setValidInputStyle(target, inputName, setInputErrorMessage);
        } else {
            setInvalidInputStyle(target, inputName, setInputErrorMessage, errorMessage);
        }
    }

    switch (inputName) {
        case "First Name":
        case "Last Name":
            checkForValidRegex(nameRegex, "Please use only letters and no spaces in your name.");
            break;
        case "Email":
            checkForValidRegex(emailRegex, "Please enter a valid email address.");
            break;
        case "Phone":
            checkForValidRegex(phone, "Please enter a valid Phone Number.");
            break;
        case "Password":
            setPassword(target.value);
            checkForValidRegex(passwordRegex, "Please enter a valid password that starts with 8 characters, including at least 2 letters.");
            break;
        case "Confirm Password":
            // Added if statements instead of switch statements for better readability.
            if (target.value === password) {
                setValidInputStyle(target, inputName, setInputErrorMessage);
            } else {
                setInvalidInputStyle(target, inputName, setInputErrorMessage, "");
            }
            break;
    }
}
