import React, { useState, useEffect } from 'react';
import { DataView } from 'primereact/dataview';

export default function DataTable({ data, flexItem }) {

    // State to hold product data
    const [products, setProducts] = useState([]);

    // ProductService object with a getProducts method that returns a promise that resolves with the data prop passed in via props
    const ProductService = {
        getProducts: function () {
            return new Promise((resolve, reject) => {
                resolve(data);
            });
        }
    };

    // Use effect hook to update products state when data prop changes
    useEffect(() => {
        ProductService.getProducts().then((data) => setProducts(data));
    }, [data]);

    // itemTemplate function that takes a product and returns the JSX for the grid item
    const itemTemplate = (product) => {

        if (!product) return;
        return flexItem(product);
        
    };

    return (
        // Render a DataView component from PrimeReact with the products array as the value prop, countOfRows as the number of rows per page, and itemTemplate as the function to render each item
        <div className="card w-100">
            <DataView value={products} paginator={true} rows={25} itemTemplate={itemTemplate} layout={'flex'} />
        </div>
    )
}