import { Link } from "react-router-dom";
import Button from "../Button/Button";
import SubHeader from "../SubHeader/SubHeader";
import EditProfileInputs from "./EditProfileInputs";

const EditProfile = () => {

    return (

        <div className="bg-white edit-profile" style={{ minHeight: "100vh" }}>

            {/* This The Custom Header  */}

            <SubHeader setClasses="d-none" />

            <h1 className="text-center mb-5" style={{ fontStyle: "italic" }}>Edit Profile Data</h1>

            <div className="container">

                <form className="w-75 w-sm-100 m-auto" onSubmit={e => e.preventDefault()}>

                    {/* We Import This From This File EditProfileInputs*/}
                    <EditProfileInputs />

                    <div className="d-flex mt-5 justify-content-end align-items-center">

                        {/* Those buttons are a globel Buttons In our Applaction */}
                        <Link to={"/profile"}><Button setClass="me-3">Discrad Changes</Button></Link>

                        <Button>Save Changes</Button>

                    </div>

                </form>

            </div>

        </div>
    )
};


export default EditProfile;