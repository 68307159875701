import { useSelector } from "react-redux";
import Header from "../Header/Header";
import VideoPosterCard from "../VideoStyleing/VideoPosterCard/VideoPosterCard";
import DataTable from "../DataTable/DataTable";
const MyList = () => {

    const myList = useSelector(store => store.list);

    const flexTemplate = (product) => {

        return (

            <div className="mb-5 me-2" style={{ width: "calc(94% / 5)" }}><VideoPosterCard card={product} WatchPath={`/watchseries?${product.id}`} /></div>

        );
    };
    return (
        <div className="pb-5 ">

            <Header />

            <div className="container">

                <h1 className="mb-5 text-white text-center">My List</h1>

                <div className="d-flex justify-content-center flex-wrap wrraper-list ">

                    {/* {setListToUi} */}
                    <DataTable data={myList} messageText={"Oops You don't have any Favorite Movie or Series"} flexItem={flexTemplate} />

                </div>

            </div>

        </div>

    )
}

export default MyList;