import { createSlice } from "@reduxjs/toolkit";

const currentSeries = createSlice({
    initialState: {

        "adult": false,

        "backdrop_path": "/l5K9elugftlcyIHHm4nylvsn26X.jpg",

        "genre_ids": [
            18
        ],

        "id": 255709,

        "original_language": "ko",

        "original_title": "소원",

        "overview": "After 8-year-old So-won narrowly survives a brutal sexual assault, her family labors to help her heal while coping with their own rage and grief.",

        "popularity": 25.09,

        "poster_path": "/x9yjkm9gIz5qI5fJMUTfBnWiB2o.jpg",

        "release_date": "2013-10-02",

        "title": "Hope",

        "video": false,

        "vote_average": 8.4,

        "vote_count": 549

    },
    name: "currentSeries",

    reducers: {

        setCurrentSeries: (state, action) => {

            return action.payload;

        }
    }
});

export default currentSeries.reducer;

export const { setCurrentSeries } = currentSeries.actions;
