const { createSlice } = require("@reduxjs/toolkit");

const listSlice = createSlice({

  name: "listSlice",

  initialState: [],

  reducers: {

    addNewFavorite: (state, action) => {

      const isExist = state.find((card) => card.id === action.payload.id);

      if (!isExist) {

        state.push(action.payload);

      }

    },

    removeFavorite: (state, action) => {

      const remove = state.filter((card) => card.id !== action.payload.id);

      return (state = remove);

    }

  }

});

export default listSlice.reducer;

export const { addNewFavorite, removeFavorite } = listSlice.actions;

