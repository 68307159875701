import { Link } from "react-router-dom";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Header from "../Header/Header";
import { Search } from "../../apis/api";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { getResult } from "../Store/Slices/searchResult";
import TopRated from "../VideoStyleing/TopRated/TopRated";


// This Component Will Return The Home Page 
const Home = () => {

    const fetchSearch = new Search();

    const [value, setValue] = useState('');

    const dispatch = useDispatch();


    return (

        <div className="home-page">

            <Header />

            <div className="home-content">

                <div className="container">

                    <h1 className="mb-4">Unlimited movies, TV shows, and more.</h1>

                    <h5 className="mb-4">Watch anywhere. Cancel anytime.</h5>

                    <h5 className="mb-4">Ready to watch? Enter your Movies Or Series Name To Get It</h5>

                    <form className="search d-flex justify-content-center" onSubmit={(e) => e.preventDefault()}>

                        {/* This The Gloabl Input Component */}
                        <Input onChange={e => setValue(e.target.value)} type="search" id="searchInput" />

                        {/* This The Gloabl Button Component */}
                        <Link to={"result"}><Button onClick={() => fetchSearch.search(value, dispatch, getResult)} style={{ borderRadius: "0 8px 8px 0" }}>Get Started</Button></Link>

                    </form>

                </div>

            </div>

        </div>
    );
}

export default Home;