import useValidationForm from "../../../hocks/useValidationForm"
import useAuth from "../../../hocks/api/useAuth";
import { useState } from "react";

const useSignUp = (redirectToIfnoAuth = '/') => {

    const auth = useAuth();

    const {isValid, setInput, errors, inputs} = useValidationForm(yup => {
        return {
            first_name: yup.string().required().min(2).max(30),
            last_name: yup.string().required().min(2).max(30),
            email: yup.string().required().email().max(61),
            password: yup.string().required().min(6).max(12),
            // 
        };
    });

    const [serverError, setServerError] = useState(null);



    const onSubmit = async (event) => {
        
        event.preventDefault();

        let responseErrors;

        if (await isValid()) {
                responseErrors = await auth.register(inputs);
                setServerError(responseErrors);
        }
    };

    return {onSubmit, setInput, errors, serverError};
};

export default useSignUp;