const LoginChoiceComponent = () => {
    return (
        <div>

            <div className="up-or-hr mb-3"><span className="span-hr">Or</span></div>

            <div style={{ border: "1px solid #c8e2c8", borderRadius: "50px" }} className="text-center mb-3 position-relative">

                <button className="google-btn p-2 w-100 fw-bold text-black-50" style={{ background: "transparent", borderRadius: "inherit" }}>

                    <img src={require("../images/google.webp")} alt="google icone" className="google-icone" />

                    Continue With Google

                </button>

            </div>

            <div style={{ border: "1px solid #c8e2c8", borderRadius: "50px" }} className="text-center mb-3 position-relative">

                <button className="apple-btn p-2 w-100 fw-bold text-black-50" style={{ background: "transparent", borderRadius: "inherit" }}>

                    <i className="fa-brands fa-apple text-black"></i>

                    Continue With Apple
                    
                </button>

            </div>

        </div>
    )
}

export default LoginChoiceComponent;