const UserAgreementCheckboxes = () => {
    return (
        <div>

            <p className="d-flex align-items-center">

                <input type="checkbox" id="up-checkbox-email" />

                <label style={{ fontSize: "13px", cursor: "pointer" }} className="user-select-none" htmlFor="up-checkbox-email">Send me emails with tips on how to find talent that fits my needs.</label>

            </p>

            <p className="d-flex align-items-center">

                <input type="checkbox" id="up-checkbox-policy" />

                <label style={{ fontSize: "13px", cursor: "pointer" }} className="user-select-none" htmlFor="up-checkbox-policy">Yes, I understand and agree to the Watch 4u Terms of Service , including the User Agreement and Privacy Policy .</label>

            </p>


        </div>
    );
}

export default UserAgreementCheckboxes;