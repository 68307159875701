import { Fragment } from "react";
import Input from "../../Input/Input";
import UserAgreementCheckboxes from "../UserAgreementCheckboxes/UserAgreementCheckboxes";


const AccountCreationForm = ({ setInput, errors }) => {

    return (

        <Fragment>

            <div className="input-name flex-space-between position-relative">

                <Input type="text" onChange={(e) => setInput('first_name', e.target.value)} errorMessage={errors.first_name} className="up-name position-relative" radius="10px" placeholder="First name without space" />

                <Input type="text" onChange={(e) => setInput('last_name', e.target.value)} errorMessage={errors.last_name} className="up-name" radius="10px" placeholder="Last name without space" />

            </div>

            <Input type="text" onChange={(e) => setInput('email', e.target.value)} errorMessage={errors.email} className="w-100" radius="10px" id="email" placeholder="email address" />

            <Input type="password" onChange={(e) => setInput('password', e.target.value)} errorMessage={errors.password} className="w-100" radius="10px" placeholder="Password (more than 8 characters, including 2 characters)" />

            <Input type="password" onChange={(e) => setInput('password_confirmation', e.target.value)} errorMessage={errors.password_confirmation} className="up-password w-100" radius="10px" placeholder="Confirm Password" />

            <UserAgreementCheckboxes />

        </Fragment>
    );
}

export default AccountCreationForm;