import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton
} from 'video-react';
import "video-react/dist/video-react.css";

const Watch = ({ videoType }) => {

  const video = useSelector(store => store[`current${videoType}`]);

  return (

    <Fragment>

      <span onClick={() => window.history.back()} style={{ position: "absolute", right: "30px", top: "30px", zIndex: 10, color: 'white', fontSize: "60px", transform: "rotate(180deg)", cursor: "pointer" }}><i className="fa-solid fa-share"></i></span>

      <Player poster={`https://image.tmdb.org/t/p/original${video?.backdrop_path}`}>

        <source src="http://peach.themazzone.com/durian/movies/sintel-1024-surround.mp4" />

        <source src="http://mirrorblender.top-ix.org/movies/sintel-1024-surround.mp4" />

        <ControlBar>

          <ReplayControl seconds={10} order={1.1} />

          <ForwardControl seconds={30} order={1.2} />

          <CurrentTimeDisplay order={4.1} />

          <TimeDivider order={4.2} />

          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />

          <VolumeMenuButton disabled />

          <VolumeMenuButton />

        </ControlBar>

      </Player>

    </Fragment>

  );
}
// Define propTypes for the Button component to enforce type checking on the props.
Watch.propTypes = {
  videoType: PropTypes.string,
};


export default Watch