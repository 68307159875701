import Button from "../Button/Button";
import LoginChoiceComponent from "./LoginChoiceComponent/LoginChoiceComponent";
import SubHeader from "../SubHeader/SubHeader";
import AccountCreationForm from "./AccountCreationForm/AccountCreationForm";
import useSignUp from "./hocks/useSignUp";
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const SignUp = () => {

  const { onSubmit, setInput, errors, serverError } = useSignUp('/profile');

  return (

    <div style={{ minHeight: "100vh" }} className="bg-white">

      {/* this is the custome header that's allow to the user go back to home page or a sign up page */}
      <SubHeader />

      {serverError && <ErrorMessage error={serverError.name} message={serverError.message} />}

      <div className="sign-up-wrapper">

        <div className="container flex-center-element">

          <div style={{ width: "600px", border: "1px solid #ccc", borderRadius: "10px", }} className="p-3 mw-100">

            <form onSubmit={onSubmit}>

              <h2 className="text-center mb-5 mt-3" style={{ fontStyle: "oblique" }}>

                Sign up to Enjoy Watching

              </h2>

              {/* this is the form sign up inputs componant */}
              <AccountCreationForm setInput={setInput} errors={errors} />

              <Button style={{ borderRadius: "10px" }} setClass="w-100 mt-5 mb-3">Sign Up</Button>

            </form>

            {/* this is the Sign Up with google or apple componant  */}
            <LoginChoiceComponent />

          </div>

        </div>

      </div>

    </div>
  );
};

export default SignUp;
