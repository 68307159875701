import { createSlice } from "@reduxjs/toolkit";

const searchResult = createSlice({
    name: "searchResult",
    initialState: [],

    reducers: {

        getResult: (state, action) => {

            return state = action.payload;

        }
    }
});

export default searchResult.reducer;

export const { getResult } = searchResult.actions;