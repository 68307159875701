import { NavLink } from "react-router-dom";
import Button from "../Button/Button";

// This Componant Will show the Site Brand and Sign in button if user doesn't sign in
const SubHeader = () => {

  const isSignIn = false;

  return (

    <div className="custom-header flex-space-between" style={{ minHeight: "118px" }}>

      <div className="container flex-space-between">

        <NavLink className="text-black fw-bold" style={{ fontSize: "1.25rem" }} aria-current="page" to={"/"} >

          Watch 4u

        </NavLink>

        {!isSignIn &&

          <NavLink className={`text-white-50 fw-bold`} to="/login">

            <Button>Sign In</Button>

          </NavLink>

        }


      </div>

    </div>

  );
};

export default SubHeader;
