import React from 'react';
import PropTypes from 'prop-types';

// This Componant will be the gloabl Button Componant in our applaction
const Button = ({ setClass, ...props }) => {


    return (

        <button className={`main-btn ${setClass || ''}`} {...props}>

            <span className='text'>

                {props.children}

            </span>

        </button>
    );
};

// Define propTypes for the Button component to enforce type checking on the props.
Button.propTypes = {
    setClass: PropTypes.string,
};

export default Button;