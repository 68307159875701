import { useDispatch, useSelector } from "react-redux";

import { addNewFavorite, removeFavorite } from "../../Store/Slices/listSlice";
import PropTypes from 'prop-types';

const FavoriteIcon = ({ card }) => {

  const dispatch = useDispatch();

  const list = useSelector((store) => store.list);

  const handelFavorite = (e) => {

    e.target.classList.toggle("favorite");

    e.target.classList.contains("favorite")

      ? dispatch(addNewFavorite(card))

      : dispatch(removeFavorite(card));

  };


  return (

    <i className={`fa-solid fa-heart me-3 ${list.some((item) => item.id === card.id) ? "favorite" : ""}`}

      onClick={(e) => handelFavorite(e)} style={{ cursor: "pointer" }} ></i>
  );
};

export default FavoriteIcon;
// Define propTypes for the Button component to enforce type checking on the props.
FavoriteIcon.propTypes = {
  card: PropTypes.object,
};