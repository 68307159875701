
// import required modules
import { Swiper } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';

export default function SwiperWrapper({ items, slidesPerViewCount, autoplayDelay, textTitle }) {

    return (

        <div className="container-fluid">

            <h3 className="text-white mt-3">{textTitle}</h3>

            <Swiper
                spaceBetween={20}

                autoplay={{

                    delay: autoplayDelay,

                    disableOnInteraction: false,

                }}

                loop={true}

                navigation={true}

                pagination={{

                    clickable: true,

                }}

                modules={[Pagination, Navigation, Autoplay]}

                breakpoints={{

                    // when window width is >= 640px
                    350: {
                        slidesPerView: 1.5,
                        spaceBetween: 20
                    },
                    550: {
                        slidesPerView: slidesPerViewCount[0],
                        spaceBetween: 20
                    },

                    // when window width is >= 768px
                    968: {
                        slidesPerView: slidesPerViewCount[1],
                        spaceBetween: 30
                    },

                    // when window width is >= 1024px
                    1150: {
                        slidesPerView: slidesPerViewCount[2],
                        spaceBetween: 40
                    },

                    // when window width is >= 1024px
                    1366: {
                        slidesPerView: slidesPerViewCount[3],
                        spaceBetween: 40
                    }

                }}

                className="mySwiper pt-4">

                {/* That's Will Render Items To UI */}
                {items}

            </Swiper>

        </div>
    )
}
