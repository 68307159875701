import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function useTopRatedEffects(showPanel, hidePanel, showPanelState) {
    const dispatch = useDispatch();

    useEffect(() => {

        if (showPanel) {

            window.addEventListener('click', () => {

                dispatch(hidePanel(false));

            })

        }

        return window.removeEventListener('click', () => dispatch(hidePanel(false)));

    }, [showPanelState]);

    useEffect(() => {

        // add event listener on mount
        if (showPanelState) {

            document.querySelector("#root").style.cssText += "max-height: 150vh !important; overflow: hidden;";

            document.querySelector(".wrapper-video-descrpiton").style.cssText += "visibility: visible; opacity: 1;";

            document.querySelector(".video-descrpiton").style.cssText += "scale: 1; visibility: visible; opacity: 1;";

        } else {

            document.querySelector("#root").style.cssText += "max-height: initial !important; overflow: auto;";

            document.querySelector(".wrapper-video-descrpiton").style.cssText += "visibility: hidden; opacity: 0;";

            document.querySelector(".video-descrpiton").style.cssText += "scale: 0; visibility: hidden; opacity: 0;";

        }

        // Remove event listener on unmount
        return () => {

            // Clear the styles
            document.querySelector("#root").style.maxHeight = "";

            document.querySelector("#root").style.overflow = "";

        };
    }, [showPanelState]);


}