import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import VideoInformationBox from "./VideoInformationBox";
import { useDispatch, useSelector } from "react-redux";
import { hidePanel } from "../../Store/Slices/panelSlice";
import { Fragment } from "react";

const VideoDescriptionPanel = ({ current }) => {

    const dispatch = useDispatch();

    const currentVideo = useSelector(store => store[current]);

    return (

        <Fragment>

            <div className="wrapper-video-descrpiton"></div>

            <div onClick={(e) => e.stopPropagation()} className="video-descrpiton overflow-hidden" style={{ '--panel-background': `url(https://image.tmdb.org/t/p/original${currentVideo?.backdrop_path})` }}>

                <button className="close-btn flex-center-element" onClick={() => dispatch(hidePanel())}>X</button>

                <div className="video-header">

                    <div className="video-title">

                        <h1 className="text-white mb-4">{currentVideo.title}</h1>

                        <div className="video-icone d-flex align-items-center">

                            <Link to={`/watch${window.location.pathname[1].toUpperCase() + window.location.pathname.slice(2)}`} onClick={() => dispatch(hidePanel())}>

                                <Button>

                                    <i className="fa-solid fa-play me-2"></i>

                                    Show


                                </Button>
                            </Link>

                        </div>

                    </div>

                </div>

                <div className="video-contant mt-5 row p-3">

                    <div className="video-text col-lg-6 col-12 text-white">

                        <p className="time-quality">New <span className="box-span">+13</span> 1 Hour 32 Minute <span style={{ "color": "#46d369" }}>Full HD Quality</span></p>

                        <div className="video-type d-flex align-items-center">

                            <p>Test</p>

                            <p>Test</p>

                            <p>Test</p>

                        </div>

                    </div>

                    <div className="col-lg-6 col-12">

                        <p className="text-white-50">Cadre Actors: <span className="text-white">Daion Janson, Jack Black, Kiven Hart</span></p>

                        <p className="text-white-50">Technical classifications: <span className="text-white">family movies, Movies based on books, Actions and adventures</span></p>

                    </div>

                </div>

                <div className="p-3">

                    <VideoInformationBox />

                </div>

            </div>

        </Fragment>

    );
}

export default VideoDescriptionPanel;