import { configureStore } from "@reduxjs/toolkit";
import currentMovie from "./Slices/currentMovie";
import panelControal from "./Slices/panelSlice";
import movieSlice from "./Slices/movieSlice";
import seriesSlice from "./Slices/seriesSlice";
import currentSeries from "./Slices/currentSeries";
import listSlice from "./Slices/listSlice";
import searchResult from "./Slices/searchResult";

const store = configureStore({
    reducer: {

        movies: movieSlice,

        series: seriesSlice,

        currentMovie: currentMovie,

        currentSeries: currentSeries,

        panelControal: panelControal,

        list: listSlice,

        searchResult: searchResult

    }
});
export default store;