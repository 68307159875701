import PropTypes from 'prop-types';
// This Component Will Be a Global Input Component
const Input = ({ errorMessage, radius, ...props }) => {

    return (

        <div className={`input-wrapper position-relative`} data-input-error={errorMessage}>

            <input {...props} style={{ "borderRadius": radius }} name={props.placeholder} />
            
        </div>
    );
}

// Define propTypes for the Button component to enforce type checking on the props.
Input.propTypes = {

    errorMessage: PropTypes.string,

    radius: PropTypes.string,

};

export default Input;

