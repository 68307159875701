import { useSelector } from "react-redux";
import Header from "../Header/Header";
import VideoPosterCard from "../VideoStyleing/VideoPosterCard/VideoPosterCard";
import DataTable from "../DataTable/DataTable";

const ResultPage = () => {

    const searchResult = useSelector(store => store.searchResult);

    const flexTemplate = (product) => {

        return (

            <div className="mb-5 me-2" style={{ width: "calc(94% / 5)" }}><VideoPosterCard card={product} WatchPath={`/watchseries?${product.id}`} /></div>

        );
    };
    return (

        <div className="pb-5 ">

            <Header />

            <div className="container">

                <h1 className="mb-5 text-white text-center">Search Result</h1>

                <DataTable data={searchResult} flexItem={flexTemplate} />

            </div>

        </div>

    )
}

export default ResultPage;