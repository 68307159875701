import { Link, NavLink, useLocation } from "react-router-dom";
import Button from "../Button/Button";
import NavItem from "./NavItem";
import { useEffect, useState } from "react";


const navList = [{ name: "Home", href: "" }, { name: "Movies", href: "movies" }, { name: "Series", href: "series" }, { name: "My List", href: "myList" }];

// This Component Will Return The Header Of Our Applaction
const Header = () => {

    const isSignIn = false;

    const location = useLocation().pathname;

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {

        if (location === "/login") {

            setShowButton(false);

        } else {
            setShowButton(true);
        }



    }, [location]);

    const navListUi = navList.map((navLink, index) => {

        // NavItem It's A component that return the item of header
        return <NavItem key={index} id={`${navLink}`} innerHtml={navLink.name} href={`/${navLink.href}`} />

    });
    
    return (

        <nav className="navbar navbar-expand-lg">

            <div className="container">

                <Link className="navbar-brand text-white fw-bold" to="/#">Watch 4u</Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

                    <span className="navbar-toggler-icon"></span>

                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul className="navbar-nav ms-auto mb-2 align-items-center mb-lg-0">

                        {navListUi}

                        {!isSignIn && showButton &&

                            <li className={`nav-item`}>

                                <NavLink className="nav-link text-white-50 fw-bold" to="/login">

                                    <Button>Sign In</Button>

                                </NavLink>

                            </li>
                        }

                    </ul>

                </div>

            </div>

        </nav>

    )
}

export default Header;