import { Link } from "react-router-dom";

const NotFound = () => {

    return (

        <div className="flex-center-element text-center text-white" style={{ "backgroundImage": `url(${require('./bg.jpg')})`, "backgroundSize": "cover", "backgroundPosition": "center center", "minHeight": "100vh" }}>

            <div className="container">

                <h1 style={{ fontSize: "40px" }}>Oops Page Not Found</h1>

                <p className="fw-bold">This page you requested could not be found.</p>

                <p className="fw-bold">May the force be with you!</p>

                <Link to={"/"} className="pe-5 ps-5 btn btn-danger">Go Back</Link>

            </div>

        </div>

    )
}

export default NotFound;