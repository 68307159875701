// This Component Will Return The Custom design Ui Error Message 
const ErrorMessage = ({ error, message }) => {
    
    return (

        <div className="p-1 err-message pt-2 pb-2 d-flex align-items-center" style={{width: "300px" , position: "fixed" , top: "100px", right: "20px" }}>

            <div className="bg-danger err-message-shape flex-center-element me-3">

                <div className="bg-white bg-danger fw-bolder flex-center-element text-danger">

                    <span>X</span>

                </div>

            </div>

            <div>
                
                <h6 className="mb-0">{typeof error == 'string'? error: 'errorName'}</h6>

                <p className="mb-0" style={{ fontSize: "14px" }}>{typeof message == 'string'? message: 'error message'}</p>

            </div>

        </div>

    );
}


export default ErrorMessage;