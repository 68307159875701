import { createSlice } from "@reduxjs/toolkit";

const movieSlice = createSlice({

    initialState: [],

    name: "MovieSlice",

    reducers: {
        setMovies: (state, action) => {
            // Filter out movies that already exist in the state
            const newMovies = action.payload.filter(
                (movie) => !state.some((m) => m.id === movie.id)
            );
            // Return the new state with the new movies added
            return [...state, ...newMovies];
        },
    }
});

export default movieSlice.reducer;

export const { setMovies } = movieSlice.actions;