import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import Header from "../../Header/Header";
import { hidePanel, showPanel } from "../../Store/Slices/panelSlice";
import VideoDescriptionPanel from "../VideoDetails/VideoDescriptionPanel";
import useTopRatedEffects from '../../../hocks/useTopRatedEffects/useTopRatedEffects';



const TopRated = ({ videoType, currentVideo }) => {

    let data = useSelector(store => store[currentVideo]);

    let showPanelState = useSelector(store => store.panelControal);

    const dispatch = useDispatch();


    // this function that return the useEffect of this file
    useTopRatedEffects(showPanel, hidePanel, showPanelState);

    return (

        <div className="movies-cover position-relative" style={{ backgroundImage: `url(https://image.tmdb.org/t/p/original${data?.backdrop_path})` }}>

            <Header />

            <div style={{ "zIndex": 20 }} className='movie-text' >

                <div className="text-white">

                    <h1 style={{ fontSize: "40px" }} className="fw-bolder mb-3">{data?.title}</h1>

                    <h4 style={{ maxWidth: "50%" }} className="mb-5">{data?.overview.slice(0, 150)}</h4>

                    <div className="flex-wrap flex-center-element">

                        <Link to={`/watch${videoType}?${data?.id}`}>

                            <Button setClass="main-btn mb-3 me-4 cover-show-btn">

                                <i className={'fa-solid fa-play me-2'}></i>

                                Watch

                            </Button>

                        </Link>

                        <span onClick={(e) => {
                            e.stopPropagation();
                            dispatch(showPanel());
                        }}>

                            <Button setClass="main-btn mb-3">

                                <i className={'fa-solid fa-exclamation me-2'}></i>

                                More Details

                            </Button>

                        </span>

                    </div>

                </div>

            </div>
            <VideoDescriptionPanel current={currentVideo} />

        </div>

    )
}
// Define propTypes for the Button component to enforce type checking on the props.
TopRated.propTypes = {
    videoType: PropTypes.string,
};

export default TopRated;