import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';

// This Component Will Return The Nav Item in Header
const NavItem = ({ id, innerHtml, href }) => {

    return (

        <li className="nav-item">

            <NavLink className="nav-link text-white-50 fw-bold" aria-current="page" id={id} to={href}>{innerHtml}</NavLink>

        </li>
    )
}

// Define propTypes for the Button component to enforce type checking on the props.
NavItem.propTypes = {

    id: PropTypes.string,

    innerHtml: PropTypes.string.isRequired,

    href: PropTypes.string,

};

export default NavItem;