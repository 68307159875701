const VideoInformationBox = () => {

    return (

        <div>

            <h3 className="text-white mb-3">About Video</h3>

            <div>

                <p className="text-white-50" style={{ fontSize: "13px" }}>director: <span className="text-white" style={{ fontSize: "16px" }}>ProNabowy</span></p>

                <p className="text-white-50" style={{ fontSize: "13px" }}>Cadre Actors: <span className="text-white" style={{ fontSize: "16px" }}>Nabowy Just Testing</span></p>

                <p className="text-white-50" style={{ fontSize: "13px" }}>Writer: <span className="text-white" style={{ fontSize: "16px" }}>The Writer OF this Vido (Nabowy)</span></p>

                <p className="text-white-50" style={{ fontSize: "13px" }}>Technical classifications: <span className="text-white" style={{ fontSize: "16px" }}>Family movies, Movies based on books, Actions and adventures</span></p>

                <p className="text-white-50" style={{ fontSize: "13px" }}>attribute: <span className="text-white" style={{ fontSize: "16px" }}>Testing</span></p>

                <p className="text-white-50" style={{ fontSize: "13px" }}>Maturity rating: <span className="text-white box-span" style={{ fontSize: "16px" }}>+13</span></p>

            </div>

        </div>

    )
}

export default VideoInformationBox;