import SubHeader from "../SubHeader/SubHeader";
import Button from "../Button/Button"
import { Link } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";

const UserProfile = () => {

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    return (

        <div className="w-100 bg-white" >

            <SubHeader setClasses="d-none" />

            <div className="container">

                <div>
                    <h1 className='font-medium mt-5' style={{ fontSize: "25px" }}>My Profile</h1>

                    <p className='text-[#8080c2]'>Edit your information</p>
                </div>


                <div className='position-relative p-4 d-fflex flex-col justify-content-end' style={{ minHeight: "400px" }}>

                    <img src={require('./profile-bg.png')} style={{ left: 0, top: 0, height: "233px" }} className='position-absolute profile-img w-100' />

                    <div className='d-flex align-items-center justify-content-between w-100 position-relative'>

                        <div className='position-relative user-img w-fit'>

                            <img src={require('./profile-img.png')} style={{ width: "270px", height: "270px", borderRadius: "50%" }} className=' sm:w-[180px] sm:h-[180px] lg:w-[270px] lg:h-[270px]' />

                            <div className='upload-img shadow w-[40px] h-[40px] sm:w-[60px] sm:h-[60px]'>

                                <input type='file' id='upload_user_image' className="p-0" style={{ maxWidth: "100%", opacity: 0, appearance: "none", width: 0 }} />

                                <label htmlFor='upload_user_image' style={{ cursor: "pointer" }}>

                                    <svg width="30" height="20" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">

                                        <path d="M10.25 5.93151V6.68151C10.5094 6.68151 10.7503 6.54751 10.8871 6.32718L10.25 5.93151ZM13.3125 1V0.25C13.0531 0.25 12.8122 0.384001 12.6754 0.604332L13.3125 1ZM24.6875 1L25.3246 0.604332C25.1878 0.384001 24.9469 0.25 24.6875 0.25V1ZM27.75 5.93151L27.1129 6.32718C27.2497 6.54751 27.4906 6.68151 27.75 6.68151V5.93151ZM2.25 26.8904V10.0411H0.75V26.8904H2.25ZM5.875 6.68151H10.25V5.18151H5.875V6.68151ZM10.8871 6.32718L13.9496 1.39567L12.6754 0.604332L9.61286 5.53584L10.8871 6.32718ZM13.3125 1.75H24.6875V0.25H13.3125V1.75ZM24.0504 1.39567L27.1129 6.32718L28.3871 5.53584L25.3246 0.604332L24.0504 1.39567ZM27.75 6.68151H32.125V5.18151H27.75V6.68151ZM35.75 10.0411V26.8904H37.25V10.0411H35.75ZM35.75 26.8904C35.75 28.7023 34.1719 30.25 32.125 30.25V31.75C34.9106 31.75 37.25 29.6178 37.25 26.8904H35.75ZM32.125 6.68151C34.1719 6.68151 35.75 8.22917 35.75 10.0411H37.25C37.25 7.31369 34.9106 5.18151 32.125 5.18151V6.68151ZM2.25 10.0411C2.25 8.22917 3.82806 6.68151 5.875 6.68151V5.18151C3.08945 5.18151 0.75 7.31369 0.75 10.0411H2.25ZM5.875 30.25C3.82806 30.25 2.25 28.7023 2.25 26.8904H0.75C0.75 29.6178 3.08945 31.75 5.875 31.75V30.25ZM24.8125 17.4384C24.8125 20.3851 22.2551 22.8527 19 22.8527V24.3527C22.9937 24.3527 26.3125 21.3006 26.3125 17.4384H24.8125ZM19 22.8527C15.7449 22.8527 13.1875 20.3851 13.1875 17.4384H11.6875C11.6875 21.3006 15.0063 24.3527 19 24.3527V22.8527ZM13.1875 17.4384C13.1875 14.4916 15.7449 12.024 19 12.024V10.524C15.0063 10.524 11.6875 13.5761 11.6875 17.4384H13.1875ZM19 12.024C22.2551 12.024 24.8125 14.4916 24.8125 17.4384H26.3125C26.3125 13.5761 22.9937 10.524 19 10.524V12.024ZM32.125 30.25H5.875V31.75H32.125V30.25Z" fill="#457673" />

                                    </svg>

                                </label>

                            </div>

                        </div>

                        <div className='user-bg shadow'>

                            <input type='file' id='upload_user_background_image' className="p-0" style={{ maxWidth: "100%", opacity: 0, appearance: "none", width: 0 }} />

                            <label htmlFor='upload_user_background_image' style={{ cursor: "pointer" }}>

                                <svg width="30" height="20" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">

                                    <path d="M10.25 5.93151V6.68151C10.5094 6.68151 10.7503 6.54751 10.8871 6.32718L10.25 5.93151ZM13.3125 1V0.25C13.0531 0.25 12.8122 0.384001 12.6754 0.604332L13.3125 1ZM24.6875 1L25.3246 0.604332C25.1878 0.384001 24.9469 0.25 24.6875 0.25V1ZM27.75 5.93151L27.1129 6.32718C27.2497 6.54751 27.4906 6.68151 27.75 6.68151V5.93151ZM2.25 26.8904V10.0411H0.75V26.8904H2.25ZM5.875 6.68151H10.25V5.18151H5.875V6.68151ZM10.8871 6.32718L13.9496 1.39567L12.6754 0.604332L9.61286 5.53584L10.8871 6.32718ZM13.3125 1.75H24.6875V0.25H13.3125V1.75ZM24.0504 1.39567L27.1129 6.32718L28.3871 5.53584L25.3246 0.604332L24.0504 1.39567ZM27.75 6.68151H32.125V5.18151H27.75V6.68151ZM35.75 10.0411V26.8904H37.25V10.0411H35.75ZM35.75 26.8904C35.75 28.7023 34.1719 30.25 32.125 30.25V31.75C34.9106 31.75 37.25 29.6178 37.25 26.8904H35.75ZM32.125 6.68151C34.1719 6.68151 35.75 8.22917 35.75 10.0411H37.25C37.25 7.31369 34.9106 5.18151 32.125 5.18151V6.68151ZM2.25 10.0411C2.25 8.22917 3.82806 6.68151 5.875 6.68151V5.18151C3.08945 5.18151 0.75 7.31369 0.75 10.0411H2.25ZM5.875 30.25C3.82806 30.25 2.25 28.7023 2.25 26.8904H0.75C0.75 29.6178 3.08945 31.75 5.875 31.75V30.25ZM24.8125 17.4384C24.8125 20.3851 22.2551 22.8527 19 22.8527V24.3527C22.9937 24.3527 26.3125 21.3006 26.3125 17.4384H24.8125ZM19 22.8527C15.7449 22.8527 13.1875 20.3851 13.1875 17.4384H11.6875C11.6875 21.3006 15.0063 24.3527 19 24.3527V22.8527ZM13.1875 17.4384C13.1875 14.4916 15.7449 12.024 19 12.024V10.524C15.0063 10.524 11.6875 13.5761 11.6875 17.4384H13.1875ZM19 12.024C22.2551 12.024 24.8125 14.4916 24.8125 17.4384H26.3125C26.3125 13.5761 22.9937 10.524 19 10.524V12.024ZM32.125 30.25H5.875V31.75H32.125V30.25Z" fill="#457673" />

                                </svg>

                            </label>

                        </div>


                    </div>



                </div>

                <form className="profile-input">

                    <h3 className='mb-3'>My information</h3>

                    <div className='d-flex align-items-center justify-content-between mb-3 '>

                        <input disabled type="text" className="border text-black" style={{ width: '48%' }} placeholder="Your Name" />
                        <input disabled type="text" className="border text-black" style={{ width: '48%' }} placeholder="Your Second Name" />

                    </div>

                    <input type="email" disabled className="w-100" placeholder="Email@example.com"></input>


                    <textarea placeholder="Bio" className="w-100 mt-3"></textarea>

                    <input type="text" className="w-100" placeholder="US +1"></input>


                    <div className="d-flex align-items-center justify-content-between flex-wrap mb-5">
                        <div className="card" style={{ width: "48%" }}>
                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                placeholder="Select a City" />
                        </div>
                        <div className="card" style={{ width: "48%" }}>
                            <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} options={cities} optionLabel="name"
                                placeholder="Select a Country" />
                        </div>

                    </div>
                </form>

                <div className='d-flex align-items-center justify-content-end p-3 mt-6  flex-wrap'>

                    <div className='mb-3 d-flex justify-content-between align-items-center'>

                        <Link to={'/'}>

                            <Button setClass={'!text-[#AC3F4D] !p-4  me-4'}>Cancel</Button>

                        </Link>

                        <Button setClass={'!text-white !p-4 !px-6 sm:!px-16'} >Confirm Changes</Button>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default UserProfile;