import { Fragment, useState } from "react";
import Input from "../Input/Input";
import { validateInput } from "../utilities/utilities";

// This Componant will return the input of the edit Profile page
const EditProfileInputs = () => {

    const [password, setPassword] = useState("");

    const [inputErrorMessage, setInputErrorMessage] = useState({

        ["First Name"]: "",

        ["Last Name"]: "",

        Password: "",

        Phone: "",

    });

    return (
        <Fragment>

            <Input type="text" onChange={(e) => validateInput(e.target, "First Name", setInputErrorMessage)} errorMessage={`${inputErrorMessage["First Name"]}`} className="w-100" radius="10px" id="edit-user-name" placeholder="Mahmoud" />

            <Input type="text" onChange={(e) => validateInput(e.target, "Last Name", setInputErrorMessage)} errorMessage={`${inputErrorMessage["Last Name"]}`} className="w-100" radius="10px" id="edit-user-name" placeholder="Nabowy" />

            <Input type="password" minLength={8} maxLength={16} onChange={(e) => validateInput(e.target, "Password", setInputErrorMessage, password, setPassword)} errorMessage={`${inputErrorMessage.Password}`} className="w-100" radius="10px" placeholder="Password (more than 8 characters, including 2 characters)" />

            <Input type="number" className="w-100" radius="10px" onChange={(e) => validateInput(e.target, "Phone", setInputErrorMessage)} errorMessage={`${inputErrorMessage["Phone"]}`} id="edit-phone" placeholder="Phone 01125480514" />

        </Fragment>
    )
}

export default EditProfileInputs;