import PropTypes from "prop-types"
import { useState } from "react";
import { useDispatch } from "react-redux";
import Loaders from "../../Loaders/Loaders";
import { setCurrentMovie } from "../../Store/Slices/currentMovie";
import { setCurrentSeries } from "../../Store/Slices/currentSeries";
import { setCurrentMovieAction } from "../../utilities/utilities";
import FavoriteIcon from "./FavoriteIcon";
import { Link } from "react-router-dom";

const VideoPosterCard = ({ card, WatchPath }) => {

  const dispatch = useDispatch();

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handelRate = (e) => {

    return e.target.classList.toggle("rate");

  };

  const handleImageLoad = () => {

    setIsImageLoaded(true);

  };

  return (

    <div className="movie-card text-white-50" data-id={card.id}>

      <div className="img-poster w-100 position-relative" style={{minHeight: "270px"}} onClick={() =>

        setCurrentMovieAction(card, setCurrentMovie, setCurrentSeries, dispatch)}>

        {!isImageLoaded && <Loaders />}

        <Link to={WatchPath && WatchPath}>

          <img src={`https://www.themoviedb.org/t/p/w220_and_h330_face${card.poster_path}`} onLoad={handleImageLoad} className="mw-100"

            width="100" height="100" alt={card.overview} />

        </Link>

      </div>

      <div>

        <h6 className="pe-2 ps-2 text-nowrap overflow-hidden">{card.title.slice(0, 20) + "..."}</h6>

        <div className="flex-space-between p-2">

          <span>{card.release_date.slice(0, 4)}</span>

          <div className="flex-space-between">

            <FavoriteIcon card={card} />

            <i className="fa-solid fa-star me-3" style={{ cursor: "pointer" }} onClick={(e) => handelRate(e)} ></i>

            <span>{card.vote_average}</span>

          </div>

        </div>

      </div>

    </div>

  );

};


// Define propTypes for the Button component to enforce type checking on the props.
VideoPosterCard.propTypes = {
  card: PropTypes.object,
};
export default VideoPosterCard;
