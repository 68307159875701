import { useState } from 'react';
import api from './api';

const useAuth = () => {

    const [status, setStatus] = useState('');

    const login = async (objectOfCredentials) => {

        let response;

        try {
            setStatus('loading');
            response = await api.post('/api/sign-in', objectOfCredentials);
            setStatus('done');
            console.log(response);
            return null;
        }
        catch (err) {
            return {name: err.name, message: err.message};
        }
    };

    const logout = async () => {};

    const register = async (objectOfCredentials) => {

        let response;
        
        try {
            setStatus('loading');
            response = await api.post('/api/sign-up', objectOfCredentials)
            console.log(response);
            setStatus('done');
            return null;
        }
        catch(err) {
            return {name: err.name, message: err.message};
        }
    };
    
    const user = async () => {
        return {};
    };

    return {login, logout, register, user};
};

export default useAuth;