import { Route, Routes } from "react-router-dom";
import Header from "./componants/Header/Header";
import Home from "./componants/Home/Home";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Series from "./componants/Series/Series";
import MyList from "./componants/MyList/MyList";
import { Fragment } from "react";
import Watch from "./componants/Watch/Watch";
import Movies from "./componants/Moveis/Movies";
import NotFound from "./componants/NotFound/NotFound";
import SignUp from "./componants/SignUp/SignUp";
import UserProfile from "./componants/Profile/UserProfile";
import SignIn from "./componants/SignIn/SignIn";
import EditProfile from "./componants/EditProfile/EditProfile";
import ResultPage from "./componants/ResultPage/ResultPage";
import UpButton from "./componants/UpButton/UpButton";
import useCSRF from './hocks/api/useCSRF';

function App() {

  useCSRF();

  return (
    <div className="App">

      <UpButton />

      <Fragment>

        <Routes>

          <Route path="/" element={<Home />} />

          <Route path="/login" element={<SignIn />} />

          <Route path="/signUp" element={<SignUp />} />

          <Route path="/movies" element={<Movies />} />

          <Route path="/series" element={<Series />} />

          <Route path="/myList" element={<MyList />} />

          <Route path="/watchMovies" element={<Watch videoType="Movie" />} />

          <Route path="/watchSeries" element={<Watch videoType="Series" />} />

          <Route path="*" element={<NotFound />} />

          <Route path="/profile" element={<UserProfile />} />

          <Route path="/profile/edit" element={<EditProfile />} />

          <Route path="/result" element={<ResultPage />} />

        </Routes>

      </Fragment>

    </div>
  );
}

export default App;
