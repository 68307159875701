import { createSlice } from "@reduxjs/toolkit";

const panelSlice = createSlice({

    initialState: false,

    name: "panel",

    reducers: {

        showPanel: (state, action) => {

            return state = true;

        },
        hidePanel: (state, action) => {

            return state = false;

        }

    }

});

export default panelSlice.reducer;

export const { showPanel, hidePanel } = panelSlice.actions;