import { Link } from "react-router-dom";
import Button from "../Button/Button";
import Input from "../Input/Input";
import useSignIn from "./hocks/useSignIn";
import Header from "../Header/Header";

const SignIn = () => {

    const { onSubmit, setInput, errors, serverError } = useSignIn();

    return (
        <div className="login-page">

            <Header />

            <div className="form-login p-5 mw-100" style={{ backgroundColor: "#00000099" }}>

                <form className="flex-center-element flex-column" onSubmit={onSubmit}>

                    <h2 className="mb-5">Sign In</h2>

                    <Input onChange={e => setInput('email', e.target.value)} errorMessage={errors.email} type="email" placeholder="Enter Your Email" className="mb-3 p-3 mw-100" radius="7px" id="form-email" />

                    <Input onChange={e => setInput('password', e.target.value)} errorMessage={errors.password} type="password" className="mb-3 p-3 mw-100" placeholder="Enter Your Password" radius="7px" id="form-password" />

                    <Button setClass="w-100" >Sign In</Button>

                    <h6 className="mt-5 mb-2"> Don't have an account?</h6>

                    <Link to="/signUp" ><Button setClass="w-50">Sign Up</Button></Link>

                </form>

            </div>

        </div>

    )
}

export default SignIn;