import axios from "axios";

const baseUrl = "https://api.themoviedb.org/3/movie/top_rated?api_key=";
const key = '7b5428bc0a25a279e612b7cbfdd9d3ae';

export class FetchMovies {

    getMovies(pageNum, dispatch, state) {

        axios({
            method: "get",
            url: `${baseUrl}${key}&language=en-US&page=${pageNum}`
        })

            .then(response => {

                return dispatch(state(response.data.results));

            })

            .catch(error => {
                console.log(error, "Fuiled");
            })

    }

}

export class FetchSeries {

    getSeries(pageNum, dispatch, state) {

        axios({
            method: "get",
            url: `${baseUrl}${key}&language=en-US&page=${pageNum}`
        })

            .then(response => {

                return dispatch(state(response.data.results));

            })

            .catch(error => {
                console.log(error, "Fuiled");
            })

    }

}

export class Search {

    search(query, dispatch, state) {
        axios({
            method: "get",
            url: `https://api.themoviedb.org/3/search/movie?query=${encodeURI(query)}&api_key=${key}`,
            headers: {
                accept: "application/json",
            },
        })
            .then((response) => {
                dispatch(state(response.data.results));
                console.log(response.data.results);
                return response;
            })
            .catch((error) => {
                console.log(error, "Failed");
            });
    }
}