import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../Loaders/PageLoader/PageLoader";
import TopRated from "../VideoStyleing/TopRated/TopRated";
import { SwiperSlide } from "swiper/react";
import VideoPosterCard from "../VideoStyleing/VideoPosterCard/VideoPosterCard";
import SwiperWrapper from "../SwiperWrapper/SwiperWrapper";
import { FetchSeries } from "../../apis/api";
import { setsetSeries } from "../Store/Slices/seriesSlice";
const renderData = (data) => {

    return data && data.map(card => <SwiperSlide key={card.id}><VideoPosterCard card={card} /></SwiperSlide>);

}


const Series = () => {

    const slidePerviewCount = [2.5, 4.5, 5.5, 6.5];

    const data = useSelector(store => store.series);

    const [loader, setLoader] = useState(true);

    const fetchSeries = new FetchSeries();

    const dispatch = useDispatch();


    useEffect(() => {

        fetchSeries.getSeries(9, dispatch, setsetSeries);
        fetchSeries.getSeries(10, dispatch, setsetSeries);
        fetchSeries.getSeries(11, dispatch, setsetSeries);
        fetchSeries.getSeries(12, dispatch, setsetSeries);
        fetchSeries.getSeries(13, dispatch, setsetSeries);
        fetchSeries.getSeries(14, dispatch, setsetSeries);
        fetchSeries.getSeries(15, dispatch, setsetSeries);
        fetchSeries.getSeries(16, dispatch, setsetSeries);

    }, []);



    // fetch data
    useEffect(() => {


        // add event listener on mount
        document.body.classList.add("overflow-hidden");

        // Remove event listener on unmount
        return () => {

            document.body.classList.remove("overflow-hidden");

        }
    }, []);

    useEffect(() => {

        // add event listener on mount
        if (!loader) {

            document.body.classList.remove("overflow-hidden");

        }

        // Remove event listener on unmount
        return () => {

            document.body.classList.remove("overflow-hidden");

        }
    }, [loader]);


    return (

        <div className="mb-4" onLoad={() => setLoader(false)}>

            {loader && <PageLoader />}

            <TopRated videoType="series" currentVideo={'currentSeries'} />

            <SwiperWrapper items={renderData(data?.length && data.slice(0, 20))} slidesPerViewCount={slidePerviewCount} autoplayDelay={2000} textTitle={"Watch Series Together"}></SwiperWrapper>

            <SwiperWrapper items={renderData(data?.length && data.slice(20, 40))} slidesPerViewCount={slidePerviewCount} autoplayDelay={3000} textTitle={"Watch Series Together"}></SwiperWrapper>

            <SwiperWrapper items={renderData(data?.length && data.slice(40, 60))} slidesPerViewCount={slidePerviewCount} autoplayDelay={4000} textTitle={"Watch Series Together"}></SwiperWrapper>

            <SwiperWrapper items={renderData(data?.length && data.slice(60, 80))} slidesPerViewCount={slidePerviewCount} autoplayDelay={6000} textTitle={"Watch Series Together"}></SwiperWrapper>

            <SwiperWrapper items={renderData(data?.length && data.slice(80, 100))} slidesPerViewCount={slidePerviewCount} autoplayDelay={5000} textTitle={"Watch Series Together"}></SwiperWrapper>

            <SwiperWrapper items={renderData(data?.length && data.slice(100, 120))} slidesPerViewCount={slidePerviewCount} autoplayDelay={8000} textTitle={"Watch Series Together"}></SwiperWrapper>

            <SwiperWrapper items={renderData(data?.length && data.slice(120, 140))} slidesPerViewCount={slidePerviewCount} autoplayDelay={10000} textTitle={"Watch Series Together"}></SwiperWrapper>

            <SwiperWrapper items={renderData(data?.length && data.slice(140, 160))} slidesPerViewCount={slidePerviewCount} autoplayDelay={9000} textTitle={"Watch Series Together"}></SwiperWrapper>


        </div>

    )
};

export default Series;