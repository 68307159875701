import { useState } from 'react';
import * as yup from 'yup';

const useValidationForm = (createYupRules = (yup) => { return {} }) => {

    const [inputs, setInputs] = useState({})

    const [errors, setErrors] = useState({});


    const validate = async (rulesObject) => {

        const scema = yup.object().shape(rulesObject);

        try{
            setErrors({});
            await scema.validate(inputs);
        } catch(error) {
            setErrors({ [error.path]: error.message });
            return false;
        }

        return true;
    };

    const setInput = async (inputName, inputValue) => {
        
        let rules, validationRule;

        inputs[inputName] = inputValue;

        rules = createYupRules(yup);

        if (rules.hasOwnProperty(inputName)) {
            validationRule = {[inputName]: rules[inputName]};
            return validate(validationRule);
        }

    };

    const isValid = async () => {
        return await validate( createYupRules(yup) );
    };


    return {inputs, setInput, isValid, errors};
}

export default useValidationForm;