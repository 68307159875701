import { useEffect } from "react";
import Button from "../Button/Button";

const UpButton = () => {

    // Reset Scroll Y To 0
    const handelScrolling = () => {

        return window.scrollTo({

            top: 0,

            behavior: "smooth"

        });

    };
    const handelApperButton = () => {

        const upButton = document.querySelector(".up-btn");

        window.scrollY >= 750 ? upButton.classList.add("show") : upButton.classList.remove("show");

    }
    useEffect(() => {

        handelApperButton();

        window.addEventListener("scroll", () => handelApperButton());

        return () => window.removeEventListener("scroll", () => handelApperButton());

    }, []);


    return (

        <Button onClick={() => handelScrolling()} setClass={"up-btn"}>Up</Button>

    )
}

export default UpButton;