import axios from "axios";

const api = axios.create({
    baseURL: 'http://localhost:8000',
    withCredentials: true,
    Headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json'
    }
});

export default api;