import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
//theme primereact
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core primereact
import "primereact/resources/primereact.min.css";
import './media-queries.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './componants/Store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
